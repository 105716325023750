export const WorkData = [
  {
    company: "TCET-Intern",
    designation: "Web development",
    dateJoining: "31-05-2021",
    dateEnd: "20-06-2021",
    // companyLogo:
    //   "",
    work: "Developed a website from scratch Learned about Web development skill using HTML, CSS, JavaScript, PHP.",
  },
  {
    company: "Oasis InfoByte",
    designation: "Web developer",
    dateJoining: "01-04-2022",
    dateEnd: "28-04-2022",
    // companyLogo:
    //   "",
    work: "It is a Task based internship,Learned about Web development skill using HTML, CSS, JavaScript.",
  },
];
