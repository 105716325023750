export const TestimonialData=[
{
    id:1,
    avatarimage:  require("../assets/image/prathamesh.jpg"),
    descriptions: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos, deleniti. ",
    name:"Prathamesh Awati"

},
{
    id:1,
    avatarimage:  require("../assets/image/prathamesh.jpg"),
    descriptions:   "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos, deleniti.",
    name:"Aditya Khandelwal"

},
{
    id:1,
    avatarimage:  require("../assets/image/arun.jpg"),
    descriptions: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos, deleniti. ",
    name:"Arun Maurya"

},
{
    id:1,
    avatarimage:  require("../assets/image/rakesh.jpg"),
    descriptions: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos, deleniti. ",
    name:"Rakesh Vishwakarma"

}



];