import React from "react";
import "./footer.css";
function Footer() {
  return <div className="footer">
     
        <a href="#about">
About 
</a>
<a href="#projects">
 Projects 
</a>
  <a href="#skills">
 Skills 
</a>

<a href="#work">
Work 
</a>

<br/>
  <label>© 2022 Ramkrishna Maurya, All Rights Reserved</label>

  </div>

  
}

export default Footer;
